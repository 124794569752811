<template>
  <div class="landing-form">
    <div class="dashboard-margin">
      <div class="m-0 container-form-preview">
        <div class="custom-card" >
          <div class="row align-items-end">
            <div class="col-12 col-lg-6">
              <h3 v-if="id === null">{{ $t("createLanding") }}</h3>
              <h3 v-else>{{ $t("editLanding") }}</h3>
              <base-button
                class="p-0 back-button d-flex justify-content-between px-2"
                @click="backToList"
              >
                <div class="d-flex justify-content-center align-items-center">
                  <img
                    src="/img/icons/stevie/back-shape.svg"
                    class="rotateArrow mr--2"
                    alt="Back icon"
                  />
                  <img
                    src="/img/icons/stevie/back-shape.svg"
                    class="rotateArrow mr-2"
                    alt="Back icon"
                  />
                  {{ $t("landingList") }}
                </div>
              </base-button>
            </div>
          </div>
          <div class="pt-3 px-0">
            <Form ref="landingFormRef" @submit="null" :initialValues="landing" @change="uploadForm($event, landing); hasUnsavedChanges=true">
              <section class="segment highlight-segment">
                <h3 class="subtitle-form">{{$t("formSegment.general")}}</h3>
                <div class="row">
                  <div class="col-12 col-lg-8">
                    <label class="">{{ $t("nameLanding") }}</label>
                    <base-input
                      alternative
                      :disabled="isDisabledEdit"
                      name="name"
                      v-model="landing.name"
                      :placeholder="$t('landingForm.placeholder.name')"
                      type="text"
                      maxLength="255"
                      input-classes="input-stevie"
                      label-classes="input-placeholder-stevie"
                      :class="{'has-danger': markAsInvalid(landing.name === null || landing.name === ''),}"
                    >
                    </base-input>
                    <small
                      v-if="markAsInvalid(landing.name === null || landing.name === '')"
                      class="text-danger position-absolute mt--3"
                      >{{ $t("formValidations.required") }}</small
                    >
                  </div>
                  <div class="col-12 col-lg-4">
                    <label class="">{{ $t("status") }}</label>
                    <base-input class="" name="status">
                      <el-select
                          :disabled="isDisabledEdit"
                          :placeholder="$t('select.status')"
                          v-model="landing.status"
                          @change="changeStatus"
                        >
                          <el-option
                            v-for="status in optionsForm.status"
                            :key="status.id"
                            :value="status.id"
                            :disabled="id === null"
                            :label="$t(`statusList.${status.label}`)"
                          >
                          </el-option>
                      </el-select>
                    </base-input>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 col-lg-6">
                    <label class="">
                      <span>{{ $t("url") }}</span>
                      <el-tooltip placement="top" :content="$t('urlLandingInfo')">
                        <img
                          src="/img/icons/stevie/info-shape.svg"
                          class="ml-1 icon-card"
                          alt="Info icon"
                        />
                      </el-tooltip>

                    </label>
                    <base-input
                      alternative
                      :disabled="isDisabledEdit"
                      name="url"
                      v-model="landing.url"
                      placeholder=""
                      type="text"
                      maxLength="255"
                      input-classes=""
                      label-classes="input-placeholder-stevie"
                      @input="changedUrl"
                      class="mb-0"
                      :class="{'has-danger': markAsInvalid(landing.url === null || landing.url === ''|| urlInvalidMsg) || landing.url !== null && !isValidUrl(landing.url)}"
                    >
                      <template v-slot:appendText>
                        <div class="appendText text-truncate mw-100">
                          {{`${APP_URL}/landing/`}}
                        </div>
                      </template>
                    </base-input>
                    <small
                      v-if="landing.url !== null && !isValidUrl(landing.url) || urlInvalidMsg || markAsInvalid(landing.url === null || landing.url === '')"
                      class="text-danger"
                      >{{ $t("formValidations.urlValid") }}</small
                    >
                    <small
                      v-if="urlIsTaken"
                      class="text-danger"
                      >{{ $t("formValidations.urlExist") }}</small
                    >
                  </div>
                  <div class="col-12 col-lg-2">
                    <label class="">{{ $t("backgroundColor") }}</label>
                    <div class="custom-color-picker">
                      <el-color-picker
                        :disabled="isDisabledEdit"
                        v-model="landing.background"
                        color-format="hex"
                        popper-class="custom-picker"
                        @change="landing.background = $event ? $event : '#ffffff';hasUnsavedChanges=true"
                      ></el-color-picker>
                    </div>
                  </div>
                  <div class="col-12 col-lg-4">
                    <label class="">{{ $t("categories") }}</label>
                    <base-input class="" name="categories">
                      <el-select
                        v-model="landing.categories"
                        multiple
                        collapse-tags
                        collapse-tags-tooltip
                        :placeholder="$t('select.categories')"
                        @change="hasUnsavedChanges=true"
                      >
                        <el-option
                          v-for="category in categories"
                          :key="category.id"
                          :label="$t(`templateCategories.${category.id}`)"
                          :value="category.id"
                        />
                      </el-select>
                    </base-input>
                    <small
                      v-if="markAsInvalid( landing.categories === null || Object.keys(landing.categories).length === 0)"
                      class="text-danger position-absolute mt--3"
                    >{{ $t("formValidations.required") }}</small>
                  </div>
                </div>
              </section>
              <section class="row segment">
                <h3 class="col-12 px-0 subtitle-form">{{$t("formSegment.websiteHeader")}}</h3>
                <div class="col-12 row justify-space-between mx-0 px-0 mb-3">
                  <div class="col-12 col-lg-6 px-0">
                    <label class="">{{ $t("landingForm.header") }}</label>
                    <base-input
                      :disabled="isDisabledEdit"
                      name="title_text"
                      v-model="landing.title_text"
                      :placeholder="$t('landingForm.placeholder.header')"
                      type="textarea"
                      maxLength="100"
                      input-classes="input-stevie p-2 textarea-header"
                      label-classes="input-placeholder-stevie"
                    >
                    </base-input>
                  </div>
                  <div class="col-12 col-lg-6 d-flex flex-wrap justify-content-lg-around px-0 pl-lg-3 pr-lg-0 option-text">
                    <div class="max-input mr-2">
                      <label class="">{{ $t("font") }}</label>
                      <base-input class="" name="title_font">
                        <el-select
                          :disabled="isDisabledEdit"
                          v-model="landing.title_font"
                          :placeholder="$t('select.font')"
                          @change="loadGoogleFonts();hasUnsavedChanges=true"
                        >
                            <el-option
                              v-for="font in googleFonts"
                              :key="font.family"
                              :value="font.family"
                              :label="font.family"
                            >
                            </el-option>
                        </el-select>
                      </base-input>
                    </div>
                    <div class="mr-2">
                      <label class="">{{ $t("fontSize") }}</label>
                      <base-input class="input-font-size" name="title_font_size">
                        <el-select
                          :disabled="isDisabledEdit"
                          v-model="landing.title_font_size"
                          @change="hasUnsavedChanges=true"
                        >
                            <el-option
                              v-for="size in optionsFontSize"
                              :key="size.value"
                              :value="size.value"
                              :label="size.value"
                            >
                            </el-option>
                        </el-select>
                      </base-input>
                    </div>

                    <div class="mr-2">
                      <label class="">{{ $t("landingForm.text") }}</label>
                      <div class="d-block">
                        <el-color-picker
                          :disabled="isDisabledEdit"
                          v-model="landing.title_color"
                          color-format="hex"
                          @change="landing.title_color = $event ? $event : '#000000';hasUnsavedChanges=true"
                        ></el-color-picker>
                      </div>
                    </div>

                    <div class="option">
                      <label class="">{{ $t("align") }}</label>
                      <icon-button-radio-group
                        :isDisabledEdit="isDisabledEdit"
                        :options="alignButtons"
                        button-classes="text-dark input-stevie py-1 px-3"
                        v-model:value="landing.title_align"
                        @change:modelValue="landing.title_align = $event;hasUnsavedChanges=true"
                        name="title_align"
                      >
                      </icon-button-radio-group>
                    </div>

                  </div>
                </div>

                <div class="col-12 row justify-space-between mx-0 px-0 mb-3">
                  <div class="col-12 col-lg-6 px-0">
                    <label class="">{{ $t("landingForm.subheader") }}</label>
                    <base-input
                      :disabled="isDisabledEdit"
                      name="subtitle_text"
                      v-model="landing.subtitle_text"
                      :placeholder="$t('landingForm.placeholder.subheader')"
                      type="textarea"
                      maxLength="100"
                      input-classes="input-stevie p-2 textarea-header"
                      label-classes="input-placeholder-stevie"
                    >
                    </base-input>
                  </div>

                  <div class="col-12 col-lg-6 d-flex flex-wrap justify-content-lg-around px-0 pl-lg-3 pr-lg-0 option-text">
                    <div class="max-input mr-2">
                      <label class="">{{ $t("font") }}</label>
                      <base-input class="" name="subtitle_font">
                        <el-select
                          :disabled="isDisabledEdit"
                          v-model="landing.subtitle_font"
                          :placeholder="$t('select.font')"
                          @change="loadGoogleFonts();hasUnsavedChanges=true"
                        >
                            <el-option
                              v-for="font in googleFonts"
                              :key="font.family"
                              :value="font.family"
                              :label="font.family"
                            >
                            </el-option>
                        </el-select>
                      </base-input>
                    </div>
                    <div class="mr-2">
                      <label class="">{{ $t("fontSize") }}</label>
                      <base-input class="input-font-size" name="subtitle_font_size">
                        <el-select
                          :disabled="isDisabledEdit"
                          v-model="landing.subtitle_font_size"
                          @change="hasUnsavedChanges=true"
                        >
                            <el-option
                              v-for="size in optionsFontSize"
                              :key="size.value"
                              :value="size.value"
                              :label="size.value"
                            >
                            </el-option>
                        </el-select>
                      </base-input>
                    </div>

                    <div class="mr-2">
                      <label class="">{{ $t("landingForm.text") }}</label>
                      <div class="d-block">
                        <el-color-picker
                          :disabled="isDisabledEdit"
                          v-model="landing.subtitle_color"
                          color-format="hex"
                          @change="landing.subtitle_color = $event ? $event : '#000000';hasUnsavedChanges=true"
                        ></el-color-picker>
                      </div>
                    </div>

                    <div class="option">
                      <label class="">{{ $t("align") }}</label>
                      <icon-button-radio-group
                        :isDisabledEdit="isDisabledEdit"
                        :options="alignButtons"
                        button-classes="text-dark input-stevie py-1 px-3"
                        v-model:value="landing.subtitle_align"
                        @change:modelValue="landing.subtitle_align = $event;hasUnsavedChanges=true"
                        name="subtitle_align"
                      >
                      </icon-button-radio-group>
                    </div>

                  </div>
                </div>
                <div class="col-12 col-lg-6 px-0 mb-4">
                  <label class="">{{ $t("image") }}</label>
                  <div v-show="!landing.image_header_file" class="header-image">
                    <ImageCropped
                      :isAllowedCloseModal="false"
                      :validationsImage="{maxSize: null, minHeight:null, minWidth:null, aspectRatio:null}"
                      :aspect-ratio="4"
                      file-name="image_header_file"
                      :disabled="isDisabledEdit"
                      @changeImage="landing.image_header_file = $event;hasUnsavedChanges=true"
                      imageRestriction="none"
                      :fileValue="landing.image_header_file"
                    />
                  </div>
                  <div v-show="landing.image_header_file" class="header-image dz-message p-0 size-media-ads">
                    <base-button
                      class="display-image bg-transparent border-0 shadow-none m-0 delete-ad-media p-0 h-100 w-100"
                      :disabled="isDisabledEdit"
                      @click="landing.image_header_file = null;hasUnsavedChanges=true"
                    >
                      <img class="h-100 w-100 media-ad" alt="Image placeholder" :src="getImageUrl(landing.image_header_file)">
                      <span class="text-hover"> {{ $t("dropImageHere") }}</span>
                    </base-button>
                  </div>
                  <small
                    v-if="markAsInvalid( landing.image_header_file === null || landing.image_header_file === '')"
                    class="text-danger position-absolute"
                    >{{ $t("formValidations.required") }}</small
                  >
                </div>
                <div class="col-12 col-lg-6 px-0 pl-lg-3 mb-4">
                  <label class="mt-2">{{ $t("align") }}</label>
                  <icon-button-radio-group
                    :isDisabledEdit="isDisabledEdit"
                    :options="alignButtonsPicture"
                    button-classes="text-dark input-stevie py-1"
                    :isPicture="true"
                    v-model:value="landing.image_header_align"
                    @change:modelValue="landing.image_header_align = $event;hasUnsavedChanges=true"
                    name="image_header_align"
                  >
                  </icon-button-radio-group>
                  <small
                    v-if="
                      markAsInvalid(landing.image_header_align === null ||landing.image_header_align === '')"
                    class="text-danger position-absolute"
                    >{{ $t("formValidations.required") }}</small
                  >
                </div>
              </section>
              <template v-for="(block, index) in landing.blocks" :key="index">
                <div class="row segment highlight-segment mt-1">
                  <div class="col-12 px-0 d-flex justify-content-between">
                    <h3 class="subtitle-form">{{$t("formSegment.contentBlock", [index+1])}}</h3>
                    <base-button
                      v-if="landing.blocks.length > 1"
                      @click="manageConfirmModal('deleteBlock', index)"
                      class="button-icon d-flex justify-content-center align-items-center"
                      :disabled="isLoading"
                      size="sm"
                      outline
                    >
                      <div v-if="!isLoading">
                        <em class="fas fa-trash-alt fa-lg text-danger"></em>
                      </div>
                      <clip-loader
                        v-else
                        color="#ffffff"
                        size="20px"
                        class="pt-2"
                      ></clip-loader>
                    </base-button>
                  </div>
                  <div class="col-12 col-lg-6 px-0 mb-lg-4">
                    <label>{{ $t("image") }}</label>
                    <el-upload
                      accept=".jpeg,.jpg,.png,.gif"
                      class="avatar-uploader w-100 mb-2 block-image"
                      action=""
                      drag
                      :auto-upload="false"
                      :show-file-list="false"
                      :on-change="
                        uploadFile =>
                          getImage(
                            uploadFile,
                            `image_url`,
                            block
                          )
                      "
                      :before-upload="validateImage"
                      :disabled="isDisabledEdit"
                    >
                      <div
                        v-if="
                          landing.blocks[index][
                            `image_url`
                          ] === null
                        "
                        class="el-upload__text texto"
                      >
                        {{ $t("dropImageHere") }}
                      </div>
                      <div v-else>
                          <span class="el-upload-list__item-actions">
                            <span
                              class="el-upload-list__item-delete"
                              @click.stop="handleRemove(`image_url`, block)"
                            >
                              <em class="far fa-trash-alt fa-lg"></em>
                            </span>
                          </span>
                        <img
                          :src="getImageUrl(landing.blocks[index][`image_url`])"
                          class="avatar url"
                        />
                      </div>
                    </el-upload>
                  </div>

                  <div class="col-12 col-lg-6 px-0 pl-lg-3 mb-4">
                    <label class="">{{ $t("align") }}</label>
                    <icon-button-radio-group
                      :isDisabledEdit="isDisabledEdit"
                      :options="alignButtonsPicture"
                      button-classes="text-dark input-stevie py-1"
                      :isPicture="true"
                      v-model:value="landing.blocks[index].image_align"
                      @change:modelValue="landing.blocks[index].image_align = $event;hasUnsavedChanges=true"
                      :name="`image_align_${index}`"
                    >
                    </icon-button-radio-group>
                  </div>

                  <div class="col-12 col-lg-6 px-0 mb-lg-4">
                    <label>{{ $t("text") }}</label>
                    <base-input
                      :disabled="isDisabledEdit"
                      :name="`blocks[${index}]text_text`"
                      v-model="landing.blocks[index].text_text"
                      :placeholder="$t('landingForm.placeholder.blockText')"
                      type="textarea"
                      class="mb-3"
                      maxLength="500"
                      label-classes="input-placeholder-stevie"
                      input-classes="form-control col-12 input-textarea-shadow textarea-block"
                      :rows="7"
                    >
                    </base-input>
                  </div>

                  <div class="col-12 col-lg-6 mb-4 d-flex flex-wrap justify-content-lg-around px-0 pl-lg-3 pr-lg-0 option-text">
                    <div class="max-input mr-2">
                      <label class="">{{ $t("font") }}</label>
                      <base-input class="" :name="`blocks[${index}]text_font`">
                        <el-select
                          :disabled="isDisabledEdit"
                          v-model="landing.blocks[index].text_font"
                          :placeholder="$t('select.font')"
                          @change="loadGoogleFonts();hasUnsavedChanges=true"
                        >
                            <el-option
                              v-for="font in googleFonts"
                              :key="font.family"
                              :value="font.family"
                              :label="font.family"
                            >
                            </el-option>
                        </el-select>
                      </base-input>
                    </div>
                    <div class="mr-2">
                      <label class="">{{ $t("fontSize") }}</label>
                      <base-input class="input-font-size" name="text_font_size">
                        <el-select
                          :disabled="isDisabledEdit"
                          v-model="landing.blocks[index].text_font_size"
                          @change="hasUnsavedChanges=true"
                        >
                            <el-option
                              v-for="size in optionsFontSize"
                              :key="size.value"
                              :value="size.value"
                              :label="size.value"
                            >
                            </el-option>
                        </el-select>
                      </base-input>
                    </div>
                    <div class="mr-2">
                      <label class="">{{ $t("landingForm.text") }}</label>
                      <div class="d-block">
                        <el-color-picker
                          :disabled="isDisabledEdit"
                          v-model="landing.blocks[index].text_color"
                          color-format="hex"
                          @change="landing.blocks[index].text_color = $event ? $event : '#000000';hasUnsavedChanges=true"
                        ></el-color-picker>
                      </div>
                    </div>
                    <div class="option">

                      <label class="">{{ $t("align") }}</label>
                      <icon-button-radio-group
                        :isDisabledEdit="isDisabledEdit"
                        :options="alignButtons"
                        button-classes="text-dark input-stevie py-1"
                        v-model:value="landing.blocks[index].text_align"
                        @change:modelValue="landing.blocks[index].text_align = $event;hasUnsavedChanges=true"
                        :name="`text_align_${index}`"
                      >
                      </icon-button-radio-group>
                    </div>

                  </div>
                  <div class="col-12 col-lg-6 px-0">
                    <label class="">{{ $t("landingForm.btnText") }}</label>
                    <base-input
                      :disabled="isDisabledEdit"
                      :name="`blocks[${index}]link_text`"
                      v-model="landing.blocks[index].link_text"
                      :placeholder="$t('landingForm.placeholder.btnText')"
                      type="text"
                      maxLength="250"
                      input-classes="input-stevie"
                      label-classes="input-placeholder-stevie"
                    >
                    </base-input>
                  </div>
                  <div class="col-12 col-lg-6 px-0 pl-lg-3">
                    <label class="">{{ $t("landingForm.link") }}</label>
                    <base-input
                      alternative
                      :disabled="isDisabledEdit"
                      :name="`blocks[${index}]link_url`"
                      v-model="landing.blocks[index].link_url"
                      :placeholder="$t('landingForm.placeholder.link')"
                      type="text"
                      maxLength="255"
                      input-classes="input-stevie input-link"
                      label-classes="input-placeholder-stevie"
                    >
                    </base-input>
                    <small
                      v-if="markAsInvalid(landing.blocks[index].link_url !== null && landing.blocks[index].link_url !== '' && !isValidLink(landing.blocks[index].link_url))"
                      class="text-danger position-absolute mt--3"
                      >{{ $t("formValidations.urlValid") }}</small
                    >
                  </div>
                  <div class="col-12 px-0 mb-4 px-0 d-flex option-text">
                    <div class="max-input mr-2">
                      <label class="">{{ $t("font") }}</label>
                      <base-input class="" :name="`blocks[${index}]link_font`">
                        <el-select
                          :disabled="isDisabledEdit"
                          v-model="landing.blocks[index].link_font"
                          :placeholder="$t('select.font')"
                          @change="loadGoogleFonts();hasUnsavedChanges=true"
                        >
                            <el-option
                              v-for="font in googleFonts"
                              :key="font.family"
                              :value="font.family"
                              :label="font.family"
                            >
                            </el-option>
                        </el-select>
                      </base-input>
                    </div>
                    <div class="mr-2">
                      <label class="">{{ $t("fontSize") }}</label>
                      <base-input class="input-font-size" name="link_font_size">
                        <el-select
                          :disabled="isDisabledEdit"
                          v-model="landing.blocks[index].link_font_size"
                          @change="hasUnsavedChanges=true"
                        >
                            <el-option
                              v-for="size in optionsFontSize"
                              :key="size.value"
                              :value="size.value"
                              :label="size.value"
                            >
                            </el-option>
                        </el-select>
                      </base-input>
                    </div>

                    <div class="mr-2">
                      <label class="">{{ $t("landingForm.text") }}</label>
                      <div class="d-block">
                        <el-color-picker
                          :disabled="isDisabledEdit"
                          v-model="
                            landing.blocks[index].link_color"
                          color-format="hex"
                          @change="landing.blocks[index].link_color= $event ? $event : '#ffffff';hasUnsavedChanges=true"
                        ></el-color-picker>
                      </div>
                    </div>

                    <div class="mr-2">
                      <label class="">{{ $t("landingForm.btn") }}</label>
                      <div class="d-block">
                        <el-color-picker
                          :disabled="isDisabledEdit"
                          v-model=" landing.blocks[index].link_background"
                          color-format="hex"
                          @change=" landing.blocks[index].link_background = $event ? $event : '#86d3f7';hasUnsavedChanges=true"
                        ></el-color-picker>
                      </div>
                    </div>
                    <div class="option">
                      <label class="">{{ $t("align") }}</label>
                      <icon-button-radio-group
                        :isDisabledEdit="isDisabledEdit"
                        :options="alignButtons"
                        button-classes="text-dark input-stevie py-1"
                        v-model:value="landing.blocks[index].link_align"
                        @change:modelValue="landing.blocks[index].link_align = $event;hasUnsavedChanges=true"
                        :name="`link_align_${index}`"
                      >
                      </icon-button-radio-group>
                    </div>
                  </div>
                </div>
              </template>
              <div class="row mb-2">
                <div class="col-12 d-flex justify-content-center">
                  <base-button
                    borderRadiusLeft
                    borderRadiusRight
                    @click="addBlock()"
                    class="d-flex justify-content-center align-items-center btn-light-blue"
                    :disabled="isLoading"
                    size="sm"
                    outline
                  >
                    <div class="w-100 d-flex justify-content-around align-items-center ">
                      <img
                        class="mr-1 add-icon-size"
                        src="/img/icons/stevie/add-shape-color-blue-light.svg"
                        alt="Add icon"
                      />
                      <span> {{ $t("addBlock") }}</span>
                    </div>
                  </base-button>
                </div>
              </div>
              <section class="row segment">
                <h3 class="col-12 px-0 subtitle-form">{{ $t("footer") }}</h3>
                <div class="col-12 col-lg-6 pl-0 mb-4">
                  <label class="">{{ $t("landingForm.text") }}</label>
                  <vue-editor
                    :disabled="isDisabledEdit"
                    v-model="landing.footer_text"
                    name="footer_text"
                    :editor-toolbar="basicToolbarHtmlEditor"
                    :placeholder="$t('landingForm.placeholder.footer')"
                    @text-change="detectChangesInFooterText"
                  />
                </div>
                <div class="col-12 col-lg-6 pr-lg-0 px-0 pl-lg-3 d-flex flex-wrap justify-content-lg-around h-100 option-text">
                  <div class="max-input mr-2">
                    <label class="">{{ $t("font") }}</label>
                    <base-input class="" name="footer_font">
                      <el-select
                        :disabled="isDisabledEdit"
                        v-model="landing.footer_font"
                        :placeholder="$t('select.font')"
                        @change="hasUnsavedChanges=true"
                      >
                          <el-option
                            v-for="font in googleFonts"
                            :key="font.family"
                            :value="font.family"
                            :label="font.family"
                          >
                          </el-option>
                      </el-select>
                    </base-input>
                  </div>
                  <div class="mr-2">
                    <label class="">{{ $t("fontSize") }}</label>
                    <base-input class="input-font-size" name="footer_font_size">
                      <el-select
                        :disabled="isDisabledEdit"
                        v-model="landing.footer_font_size"
                        @change="hasUnsavedChanges=true"
                      >
                          <el-option
                            v-for="size in optionsFontSize"
                            :key="size.value"
                            :value="size.value"
                            :label="size.value"
                          >
                          </el-option>
                      </el-select>
                    </base-input>
                  </div>

                  <div class="mr-2">
                    <label class="">{{ $t("color") }}</label>
                    <div class="d-block">
                      <el-color-picker
                        :disabled="isDisabledEdit"
                        v-model="landing.footer_color"
                        color-format="hex"
                        @change="landing.footer_color = $event ? $event : '#e2e6ea';hasUnsavedChanges=true"
                      ></el-color-picker>
                    </div>
                  </div>

                  <div>
                      <label class="">{{ $t("backgroundColor") }}</label>
                      <div class="d-block">
                        <el-color-picker
                          :disabled="isDisabledEdit"
                          v-model="landing.footer_background"
                          color-format="hex"
                          @change="landing.footer_background = $event ? $event : '#666666';hasUnsavedChanges=true"
                        ></el-color-picker>
                      </div>
                    </div>

                  <div class="option">
                    <label class="">{{ $t("align") }}</label>
                    <icon-button-radio-group
                      :isDisabledEdit="isDisabledEdit"
                      :options="alignButtons"
                      button-classes="text-dark input-stevie py-1 px-3"
                      v-model:value="landing.footer_align"
                      @change:modelValue="landing.footer_align = $event;hasUnsavedChanges=true"
                      name="footer_align"
                    >
                    </icon-button-radio-group>
                  </div>

                </div>

              </section>

              <section class="segment highlight-segment">
                <h3 class="subtitle-form">{{$t("formSegment.metaTags")}}</h3>
                <div class="row">
                  <div class="col-12 col-lg-6 ">
                    <div>
                      <label class="">{{ $t("title") }}</label>
                      <base-input
                        alternative
                        :disabled="isDisabledEdit"
                        name="meta_title"
                        v-model="landing.meta_title"
                        :placeholder="$t('landingForm.placeholder.metaTitle')"
                        type="text"
                        maxLength="255"
                        input-classes="input-stevie"
                        label-classes="input-placeholder-stevie"
                        :class="{'has-danger': markAsInvalid(landing.meta_title === null || landing.meta_title === ''),}"
                      >
                      </base-input>
                      <small
                        v-if="markAsInvalid(landing.meta_title === null || landing.meta_title === '')"
                        class="text-danger position-absolute mt--3"
                        >{{ $t("formValidations.required") }}</small
                      >
                    </div>
                    <div>
                      <label class="">{{ $t("description") }}</label>
                    <base-input
                      alternative
                      :disabled="isDisabledEdit"
                      name="meta_description"
                      v-model="landing.meta_description"
                      :placeholder="$t('landingForm.placeholder.metaDescription')"
                      type="textarea"
                      maxLength="255"
                      input-classes="input-stevie p-2 textarea-header form-control"
                      label-classes="input-placeholder-stevie"
                      :class="{'has-danger': markAsInvalid(landing.meta_description === null || landing.meta_description === '')}"
                    >
                    </base-input>
                    <small
                      v-if="markAsInvalid(landing.meta_description === null || landing.meta_description === '')"
                      class="text-danger position-absolute mt--3"
                      >{{ $t("formValidations.required") }}</small
                    >
                    </div>
                  </div>
                  <div class="col-12 col-lg-6 ">
                    <label>
                      <span class="label-text">
                        {{ $t("favicon") }}
                      </span>
                      <span class="sublabel">(Max: {{validationsForms.meta_favicon.maxWidth}} x {{validationsForms.meta_favicon.maxHeight}} px, Max: {{KBtoMB(validationsForms.meta_favicon.maxSize)}} MB)</span>
                    </label>
                    <div v-show="!landing.meta_favicon" class="favicon-uploader">
                      <ImageCropped
                        :isAllowedCloseModal="false"
                        :validationsImage="validationsForms.meta_favicon"
                        :aspect-ratio="1"
                        file-name="meta_favicon"
                        :disabled="isDisabledEdit"
                        @changeImage="landing.meta_favicon = $event;hasUnsavedChanges=true"
                        acceptedFiles="image/png"
                        :fileValue="landing.meta_favicon"
                      />
                    </div>
                    <div v-show="landing.meta_favicon" class="dz-message p-0 size-media-ads favicon-uploader">
                      <base-button
                        class="display-image bg-transparent border-0 shadow-none m-0 delete-ad-media p-0 h-100 w-100"
                        :disabled="isDisabledEdit"
                        @click="landing.meta_favicon = null;hasUnsavedChanges=true"
                      >
                        <img class="h-100 w-100 media-ad" alt="Image placeholder" :src="getImageUrl(landing.meta_favicon)">
                        <span class="text-hover">{{$t('landingForm.placeholder.imageMax', [validationsForms.meta_favicon.maxWidth, validationsForms.meta_favicon.maxHeight])}}</span>
                      </base-button>
                    </div>
                  </div>
                </div>
              </section>

              <section class="segment footer row d-flex justify-content-center">
                <base-button
                  borderRadiusLeft
                  borderRadiusRight
                  @click="manageLanding()"
                  class="p-0 start-campaign-button"
                  :disabled="isLoading || isDisabledEdit"
                >
                  <span v-if="!isLoading"> {{ $t("saveLanding") }}</span>
                  <clip-loader
                    v-else
                    color="#ffffff"
                    size="20px"
                    class="pt-2"
                  ></clip-loader>
                </base-button>
              </section>
            </Form>
          </div>
        </div>
        <div class="preview-landing">
          <div>
            <LandingPreview :template="landing" :urlPreview="urlFinal" :isLoading="isLoading" :isDisabledEdit="isDisabledEdit" @manageLanding="manageLanding"></LandingPreview>
          </div>
        </div>
      </div>
    </div>
    
    <ConfirmModal
      v-if="showConfirmModal"
      :showModal="showConfirmModal"
      :modalInfo="modalConfirm"
      :isLoading="isLoading"
      @confirm="modalConfirm.confirmAction(true)"
      @cancel="modalConfirm.cancelAction(false)"
      @closeModal="showConfirmModal = false"
    ></ConfirmModal>

    <ModalUnsavedChanges
      :hasUnsavedChanges="hasUnsavedChanges && isLeaveView"
      @close="closeUnsavedChanges()"
      @saveChanges="saveChanges()"
      :isLoading="isLoading"
    ></ModalUnsavedChanges>
  </div>
</template>

<script>
import { Form } from "vee-validate";
import { useRoute, useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import { computed, onMounted, onUnmounted, ref } from "vue";

import ConfirmModal from "../../Components/ConfirmModal";
import LandingPreview from "../../Components/LandingPreview.vue";
import ModalUnsavedChanges from "@/views/Components/ModalUnsavedChanges.vue";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import useForms from "@/composables/useForms";
import { BlockFormClass } from '@/util/Models/TemplateClass.js';
import { LandingFormClass, LandingClass } from '@/util/Models/LandingClass.js';
import { ElColorPicker, ElUpload, ElSelect, ElOption } from 'element-plus';
import useLandings from "@/composables/useLandings";
import { VueEditor } from "vue3-editor";
import { basicToolbarHtmlEditor, optionsAlignButtons, optionsAlignButtonsPicture, optionsFontSize } from '@/util/Settings';
import {getImageUrl} from '@/util/helperFunctions.js'
import IconButtonRadioGroup from "@/components/Stevie/IconButtonRadioGroup";
import axios from "axios";
import WebFontLoader from 'webfontloader';
import useSubscription from '@/composables/useSubscription';
import ImageCropped from '../../Components/ImageCropped.vue';
import { addHttpOrHttps } from '../../../composables/useFormatUrl';
import { allSubscriptions } from '@/util/Settings.js';


const APP_URL = process.env.VUE_APP_URL;
const GOOGLE_API_KEY = process.env.VUE_APP_GOOGLE_API_KEY;

export default {
  name: "LandingForm",
  props: ["id", "isClone"],
  components: {
    ClipLoader,
    Form,
    ConfirmModal,
    ElColorPicker,
    ElUpload,
    ElSelect,
    ElOption,
    VueEditor,
    LandingPreview,
    IconButtonRadioGroup,
    ModalUnsavedChanges,
    ImageCropped
  },
  setup(props, {emit}) {
    const landing = ref(new LandingFormClass({}));
    const deleteItemId = ref(null)
    const previewLanding = ref(null)
    const initStatus = ref(0)
    const { subscriptionPermissions, changeSuscriptionModal } = useSubscription()
    const allowPublishLanding = subscriptionPermissions.value.allowLandings
    const {getLanding, updateLanding, storeLanding, checkUniqueUrl, getCategories, categories} = useLandings()
    const router = useRouter();
    const route = useRoute();
    const toast = useToast();
    const { markAsInvalid, isValidForm, isValidLink, uploadForm, createFormData, removeHtmlTags } = useForms();
    const id = ref(null);
    const isLoading = ref(false);
    const validationsForms = ref({
      meta_favicon: {
        maxHeight: 128,
        maxWidth: 128,
        maxSize: 5242880
      }
    });
    const modalConfirm = ref({
      title: null,
      subtitle: null,
      textCancel: null,
      textConfirm: null,
      confirmAction: null,
      cancelAction: null,
      allowClose: false,
    });
    const showConfirmModal = ref(false);
    const urlInvalidMsg = ref(null);
    const checkingUrl= ref(null)
    const urlIsTaken= ref(false)
    const originalUrl= ref(null)
    const landingFormRef = ref(null);

    const optionsForm = ref({
      status: [
        { id: 0, label: "pending" },
        { id: 1, label: "ready" },
      ],
      alignsText: ["left", "center", "right"],
    });
    const alignButtons = ref(optionsAlignButtons)
    const alignButtonsPicture = ref(optionsAlignButtonsPicture)
    const googleApiKey = GOOGLE_API_KEY
    const googleFonts = ref([])
    const hasUnsavedChanges = ref(false);
    const isLeaveView = ref(false)

    const isDisabledEdit = computed({
      get: () => {
        return id.value !== null && landing.value.status == 2 || isLoading.value;
      },
    });
    const urlFinal = computed({
      get: () => {
        const basicUrl = `${APP_URL}/landing/`
        if(isValidUrl(landing.value.url)){
          return `${basicUrl}${landing.value.url}`
        }
        return basicUrl;
      },
    });
    const manageLanding = async () => {

      let requiredFields = [
        "name",
        "status",
        "url",
        "background",
        "image_header_file",
        "image_header_align",
        "meta_title",
        "meta_description",
        "categories"
      ];

      let areLinksValid = true
      landing.value.blocks.forEach(block => {
        block.link_url = addHttpOrHttps(block.link_url);

        if(block.link_url && block.link_url !== '' && !isValidLink(block.link_url)){
          areLinksValid = false
        }
      })

      landingFormRef.value.resetForm();

      if (!isValidForm(landing.value, requiredFields, isValidUrl(landing.value.url) && !urlIsTaken.value && areLinksValid)) return;

      const landingForm = new LandingClass(landing.value);
      const formData = createFormData(landingForm);

      formData.delete('blocks_images');
      landingForm.blocks_images.forEach((item, index)=>{
        formData.append(`blocks_images[${index}]`, item)
      })

      if(landing.value.meta_favicon === null) {
        formData.append('meta_favicon','')
      }
      
      let response;
      isLoading.value = true;
      if (id.value) {
        response = await updateLanding(formData, id.value);
      } else {
        response = await storeLanding(formData);
      }
      if(response && response.status === 200){
        if(!id.value){
          hasUnsavedChanges.value = false;
          backToList()
        }else{
          landing.value = new LandingFormClass(response.data)
          originalUrl.value = response.data.url;
          initStatus.value = response.data.status;
        }
      }
      if( response.url && response.url[0]){
        urlInvalidMsg.value= response.url[0];
        toast.error(response.url[0], { timeout: 3000 });
      }
      isLoading.value = false;
      hasUnsavedChanges.value = false;
      return response;
    };


    const backToList = () => {
      if(hasUnsavedChanges.value){
        isLeaveView.value = true;
        return
      }
      const savedFilters = JSON.parse(localStorage.getItem('landingsFilters'))

      router.push({ name: "LandingsList",  query: savedFilters});
    };
    const manageConfirmModal = (action, id) => {
      deleteItemId.value = id;
      let msg;
      let confirmAction;
      if(action === 'deleteBlock'){
        msg = 'deleteItem'
        confirmAction = (()=>{removeBlock(id); hasUnsavedChanges.value = true; });
      }
      modalConfirm.value = {
        title: `${msg}.title` ,
        subtitle: `${msg}.subtitle` ,
        textCancel: `no` ,
        textConfirm: `yes` ,
        allowClose: false,
        confirmAction: confirmAction,
        cancelAction: closeModal,
      };
      setTimeout(() => {
        showConfirmModal.value = true;
      }, 500);
    };
    const closeModal = () => {
      showConfirmModal.value = false
      deleteItemId.value = null;
      landing.value.status = initStatus.value

    }

    const validateImage = (event, field) => {
      //add validation in function of field
      return true;
    };
    const getImage = (file, field, object) => {
      hasUnsavedChanges.value = true;
      object[field] = file.raw;
    };

    const addBlock = () => {
      hasUnsavedChanges.value = true;
      landing.value.blocks.push(new BlockFormClass({}));
    };
    const removeBlock = (index) => {
      closeModal()
      if (landing.value.blocks.length > 1) {
        landing.value.blocks.splice(index, 1);
      }
    };
    const handleRemove = (field, form)=>{
      hasUnsavedChanges.value = true;
      form[field]=null;
    }
    const isValidUrl = (url)=>{
      return url && url.length >= 3 ? url.match(/^[A-Za-z0-9-_]+$/g) !== null : false
    }
    const changedUrl = (event)=>{
      checkUrl(event.target.value)
      urlInvalidMsg.value = null;
      urlIsTaken.value = false;
    }
    const checkUrl = async(url)=>{
      if(originalUrl.value !== null && url === originalUrl.value)return;
      if(!isValidUrl(url))return
      if(checkingUrl.value) {
        clearTimeout( checkingUrl.value)
        checkingUrl.value = null
      }
      if(url.length > 0){
        checkingUrl.value = setTimeout( async()=>{
            let response = await checkUniqueUrl(url)
            if(response && response.status === 200){
              urlIsTaken.value = response.data.is_taken
            }
        },500)
      }
    }
    const getGoogleFont = async () => {
      const route = `https://www.googleapis.com/webfonts/v1/webfonts?sort=popularity&key=${googleApiKey}`
      const response = await axios.get(route);

      if (response && response.status === 200) {
        googleFonts.value = response.data.items
      }
      googleFonts.value = googleFonts.value.concat([
        {family: "Helvetica"},
        {family: "Verdana"},
        {family: "Sans Serif"},
        {family: "Georgia"},
        {family: "Times New Roman"},
        {family: "Arial"},
      ])
    }
    const loadGoogleFonts = () => {
      let googleFonts = [landing.value.title_font, landing.value.subtitle_font, landing.value.footer_font]
      landing.value.blocks.forEach(block => {
        googleFonts.push(block.text_font, block.link_font)
      })
      googleFonts = [...new Set(googleFonts)]

      WebFontLoader.load({
        google: {
          families: googleFonts,
          // Path to stylesheet that defines font-face
          urls: ['@/assets/css/nucleo/css/nucleo.css'],
        },
        active: setFontLoaded,
      });
    }
    const setFontLoaded = () => {
      emit('font-loaded');
    }
    const saveChanges = async() => {
      isLoading.value = true;
      let response = await manageLanding();
      isLoading.value = false;
      hasUnsavedChanges.value= false;
      isLeaveView.value = false;
      if(response && response.status === 200){
        backToList()
      }else{
        hasUnsavedChanges.value = false;
      }
    };
    const closeUnsavedChanges = ()=>{
      hasUnsavedChanges.value=false;
      isLeaveView.value= false;
      backToList()
    }
    const changeStatus = (value) => {
      hasUnsavedChanges.value = true;
      if(value === 1 && !allowPublishLanding){
        const subscriptionRequired = allSubscriptions.find(subscription => subscription.permissions.allowLandings)
        changeSuscriptionModal(true, subscriptionRequired.name)
        landing.value.status = 0
      }
    }

    const KBtoMB = (kb) => {
      const mb = 1000000
      return (kb/mb).toFixed(1)
    }

    const listCategories = async () => {
      await getCategories()
    }

    const detectChangesInFooterText = (type, event) => {
      const inputChanges = event.ops[0].insert === '\n'? null : event.ops[0].insert;
      const footerFormatted = removeHtmlTags(landing.value.footer_text);
      
      if( inputChanges && inputChanges !== footerFormatted ) {
        hasUnsavedChanges.value = true;
      } 
    }

    onMounted(async () => {
      if (route.params.id) {
        id.value = route.params.id;
        isLoading.value = true;
        const response = await getLanding(route.params.id);
        if(response && response.status === 200){
          landing.value = new LandingFormClass(response.data)
          initStatus.value = response.data.status;
          originalUrl.value = response.data.url;
        }
        isLoading.value = false;
      }
      await getGoogleFont()
      await loadGoogleFonts()
      await listCategories()
    });
    onUnmounted(()=>{
      localStorage.removeItem('landingsFilters')
    })

    return {
      landing,
      id,
      isLoading,
      previewLanding,
      validationsForms,
      isDisabledEdit,
      optionsForm,
      showConfirmModal,
      modalConfirm,
      markAsInvalid,
      basicToolbarHtmlEditor,
      APP_URL,
      manageLanding,
      uploadForm,
      backToList,
      manageConfirmModal,
      validateImage,
      getImage,
      addBlock,
      removeBlock,
      getImageUrl,
      handleRemove,
      urlInvalidMsg,
      isValidUrl,
      isValidLink,
      urlFinal,
      urlIsTaken,
      changedUrl,
      alignButtons,
      alignButtonsPicture,
      googleFonts,
      loadGoogleFonts,
      hasUnsavedChanges,
      saveChanges,
      isLeaveView,
      closeUnsavedChanges,
      allowPublishLanding,
      optionsFontSize,
      landingFormRef,
      changeStatus,
      KBtoMB,
      categories,
      detectChangesInFooterText
    };
  },
};
</script>

<style scoped lang="scss">
.container-form-preview{
  display: flex;
  .preview-landing{
    min-width: 300px;
    width:300px;
    padding-left: 30px;

  }
}
  :deep() .textarea-footer{
    height: 117px!important;
  }
  .header-image{
    :deep().dz-message{
      height: 159px!important;
    }
    height: 159px!important;
  }
  .block-image{
    height: 91px!important;
  }
:deep().el-upload {
  width: 100%;
  height: 100%!important;
  .el-upload-list__item-actions{
    background-color: rgba(0,0,0, 0.5);
    opacity: 0;
    position:absolute;
    width: 100%;
    height: 100%;
    &:hover{
      opacity: 1;
      display: block;
    }
    .el-upload-list__item-delete{
      display: block;
      padding-top: 10px;
      color: #ffffff;
    }
  }
}
:deep().el-upload-dragger {
  width: 100%;
  margin: 0;
  border-radius: 5px;
  border: 2px dashed #86d3f7;
  background: transparent;
  height: 100%;

  .el-upload__text {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    color: #012454;
    font-weight: 600;

  }
  .avatar,
  .preview {
    height: 180px;
    width: 100%;
    object-fit: cover;
    &:hover{
      opacity:1
    }
  }
}
.add-icon-size {
  height: 20px;
}
.button-icon {
  background-color: none !important;
  border: 1px solid #aab1be;
  &:hover {
    background-color: transparent;
  }
}
:deep().min-height-textarea{
  height: 91px;
}
:deep().ql-toolbar{
  border: 1px solid #c6dcf5;
  border-radius: 5px !important;
  border-bottom-color: #ffffff ;
}
:deep().ql-container{
    box-shadow: inset 0 1px 3px rgba(1, 36, 84, 0.3) !important;
    border: 1px solid #c6dcf5 !important;
    border-top-color: transparent;
    border-bottom-right-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
    .ql-editor{

      min-height: 117px;
      max-height: 117px;
      box-shadow: inset 0 1px 3px rgba(1, 36, 84, 0.3) !important;
      width: 100%;
      overflow-y: auto;
      background-color: #ffffff !important;
      font-size: 13px !important;
      color: #2b2b2d !important;
      font-family: Montserrat, sans-serif;
      border: none;
      strong{
        font-weight: 600;
      }
    }
    &.ql-disabled .ql-editor{
      background-color: #E8EEF2!important;
    }
}
.h-fit{
  height: fit-content;
}
.back-button{
  width: fit-content;
}
.info-url{
  font-size: 11px;
  font-weight: 600;
}

.landing-form{
  :deep() .textarea-header{
    height: 77px!important;
    width: 100%;
  }
  :deep() .textarea-block{
    height: 91px!important;
  }
  :deep() .textarea-footer{
    height: 117px!important;
  }
  .btn-light-blue{
    border-color: #0373a8;
    color: #0373a8;
    font-size: 14px;
    font-weight: 500;
    min-width: 166px;
    height: 33px;
    margin-top: 8px;
    margin-bottom: 26px;
    &:hover{
      color: white;
      background: #0373a8;
    }
  }
  .icon-card{
    cursor: pointer;
    width: auto;
  }

  .option-text{
    .max-input{
      width: 109px;
    }
    :deep().form-group{
      margin-bottom: 0px;
    }

  }
}
.custom-picker{
  display: block;
  position: absolute;

}
:deep().input-stevie.input-link{
  padding-left: 33px!important;
  background: url('/img/icons/stevie/link-horizontal.svg') no-repeat left;
  background-size: 15px;
  background-position: 8px 11px;
}
.custom-card{
  background-color: #ffffff;
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
  padding: 20px 21px;
  border-radius: 20px;
}

.segment{
  margin: 0 -21px;
  padding: 22px 31px 21px;
  &.footer{
    padding-top: 29px;
    margin-bottom: -20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  &.highlight-segment{
    background-color: #E7F6FD;
  }

}
label:has(.sublabel){
  display: flex;
  flex-wrap: wrap;
  .label-text{
    padding-right: 5px;
  }
  .sublabel{
    text-transform: none;
    font-weight: 500;
  }
}

@media(max-width: 1350px) {
  .container-form-preview{
    flex-direction: column;
    .preview-landing{
      width: 100%;
      padding-left: 0;
      padding-top: 30px;
    }
  }
}

</style>
