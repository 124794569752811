<template>
  <div class="template" :class="isLandingPage ? 'landing-page': 'card-template'">
      <div class="d-flex justify-content-between align-items-start">
        <h4 >{{$t("preview")}}</h4>
        <el-tooltip placement="top" :content="$t('infoLandingPreview')">
          <img
            src="/img/icons/stevie/info-shape.svg"
            class="icon-card mt-1"
            alt="Info icon"
          />
        </el-tooltip>
      </div>
      <div class="content" :style="`background-color:${template.background}`">

        <div class="col-12 px-0 pb-1" :style="`text-align:${template.image_header_align}`">
            <img
                v-if="template.image_header_file === null"
                src="/img/icons/stevie/PlaceholderImg.svg"
                class="img-background px-3 w-100 mt-2"
            />
            <img
                v-else
                :src="getImageUrl(template.image_header_file)"
                class="img-background"
                ref="imgHeader"
                :onload="()=>{ getSizeImg(imgHeader)}"
            />
        </div>

        <div class="col-12 py-1 pt-3" >
          <h1 class="header mb-0" :style="`color:${template.title_color}; text-align:${template.title_align}; font-family:${template.title_font}; font-size:${template.title_font_size}px`">
            {{template.title_text || $t('landingForm.header')}}
          </h1>
        </div>

        <div class="col-12 pb-2" >
            <h4 class="subheader" :style="`color:${template.subtitle_color}; text-align:${template.subtitle_align}; font-family:${template.subtitle_font}; font-size:${template.subtitle_font_size}px`">
              {{template.subtitle_text || $t('landingForm.subheader')}}
            </h4>
        </div>

        <template v-for="(block, index) in template.blocks" :key="index">
            <div v-if="block.image_url" class="col-12 px-0 py-1" :style="`text-align:${block.image_align}`" >
                <img
                    v-if="block.image_url === null"
                    src="/img/brand/default-placeholder.png"
                    class="img-background"
                />
                <img
                    v-else
                    :src="getImageUrl(block.image_url)"
                    :ref="el => imgBlocks[index]= el"
                    :onload="()=>{ getSizeImg( imgBlocks[index])}"
                    class="img-background"
                />
            </div>
            <div class="col-12 py-1 text-block" >
                <p v-if="block.text_text" :style="`color:${block.text_color}; text-align:${block.text_align}; font-family:${block.text_font}; font-size:${block.text_font_size}px`">{{block.text_text || $t("blockDefault")}}</p>
                <p v-else class="default-title block text-truncate" :style="`color:${block.text_color}; text-align:${block.text_align}; font-family:${block.text_font}; font-size:${block.text_font_size}px`">{{ $t("blockDefault")}}</p>
            </div>
            <div  v-if="block.link_url && block.link_text" class="col-12 pt-1 pb-3 text-block" :style="`text-align:${block.link_align}`" >
                <base-button
                    borderRadiusLeft
                    borderRadiusRight
                    class="p-0 start-campaign-button btn-text border-0 mw-100 "
                    size="sm"
                    :style="`color:${block.link_color}; background-color:${block.link_background}; font-family:${block.link_font}; font-size:${block.link_font_size}px`"
                >
                {{block.link_text}}
                </base-button>
            </div>
        </template>
        <footer class="col-12 footer py-1 mt-auto" :style="`background-color:${template.footer_background};text-align:${template.footer_align}`">
            <hr v-if="!template.footer_text" class="divider">
            <div v-if="template.footer_text" class="footer_html" :style="`color:${template.footer_color}!important; font-family:${template.footer_font}; font-size:${template.footer_font_size}px`" v-html="template.footer_text"></div>
            <span v-else class="default-title" :style="`color:${template.footer_color}!important; font-family:${template.footer_font}; font-size:${template.footer_font_size}px`">
              {{$t('formSegment.footer')}}
            </span>
        </footer>
      </div>
      <div class="d-flex justify-content-end">
        <a @click="showPreview" class="btn-preview py-2" >
          {{$t('browserPreview')}}
          <img class="ml-2 icon" src="/img/icons/stevie/arrow-up-right.svg" alt="" style="width:10px; height:10px">
        </a>
      </div>
      <base-button
        borderRadiusLeft
        borderRadiusRight
        @click="$emit('manageLanding')"
        class="p-0 start-campaign-button w-100 mt-3"
        :disabled="isLoading || isDisabledEdit"
      >
        <span v-if="!isLoading"> {{ $t("saveLanding") }}</span>
        <clip-loader
          v-else
          color="#ffffff"
          size="20px"
          class="pt-2"
        ></clip-loader>
      </base-button>
  </div>
  </template>
  <script>
    const APP_URL = process.env.VUE_APP_URL;
  import {getImageUrl} from '@/util/helperFunctions.js'
  import { onUnmounted, ref, onBeforeMount } from 'vue';
  import { useRoute } from 'vue-router'
  import router from '../../router'
  import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
  export default {
    name: "LandingPreview",
    props: ["template", "urlPreview", "isDisabledEdit", "isLoading"],
    components: {
      ClipLoader
    },
    setup(props) {
      const route = useRoute()

      const imgHeader = ref(null)
      const imgBlocks = ref([])
      const maxWidth = ref(null)
      const isLandingPage = ref(false)


      const getSizeImg = (img)=>{
          if(img && img.naturalWidth){
              let imgWidth = (img.naturalWidth*100)/600;
              imgWidth = imgWidth > 100 || maxWidth.value === null ? 100 : imgWidth;
              img.style = `width:${imgWidth}%`
          }
      }
      const showPreview = ()=>{

        let templatePreview = {...props.template}
        templatePreview.image_header_file = getImageUrl(props.template.image_header_file)
        templatePreview.blocks.forEach(block => block.image_url = getImageUrl(block.image_url))

        localStorage.setItem("landingPreview", JSON.stringify(templatePreview))
        window.open(`${APP_URL}/landings/preview`, '_blank')
      }
      onBeforeMount(()=>{
          if(route.name === 'EditMail' || route.name === 'NewMail'){
              maxWidth.value = 600;
          }
          if(route.name === 'CustomLanding'){
              isLandingPage.value = true;
          }

      })

      onUnmounted(()=>{
        localStorage.removeItem("landingPreview")
      })

      return{
          getImageUrl,
          getSizeImg,
          imgHeader,
          imgBlocks,
          isLandingPage,
          showPreview
      }
    }

  }
  </script>
  <style lang="scss" scoped>
      .template{
          width: 100%;
          height: 100%;
          overflow-wrap: break-word;
          &.card-template{
              background-color: #FFFFFF;
              border-radius: 20px;
              padding: 16px;
          }
          &.landing-page .content{
              min-height: 100vh;
              display: flex;
              flex-direction: column;
          }
          .header{
            font-size: 20px;
          }
          .subheader{
            font-size: 12px;
          }
          .img-background{
              height: 100%;
              max-width: 100%;
              object-fit: cover;
          }
          .text-block p, .text-block .btn-text{
            font-size: 10px;
          }
          footer{
              font-size: 10px;
              max-width: 100%;
              .divider{
                border: 1px solid #ffff;
              }
              .footer_html{
                word-break: break-all;

                :deep()li{
                  text-align: start!important;
                }
                :deep()p{
                    font-size: inherit !important;
                }
              }
          }
          .start-campaign-button{
              min-width: fit-content;
              overflow: hidden;
          }
          .default-title{
            min-height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-transform: uppercase;
            &.block{
              margin-top: 40px;
            }
          }
          .btn-preview{
            font-size: 13px;
            font-weight: 500;
            color: #0373a8;
            display: flex;
            align-items: center;
            cursor: pointer;
            &.disabled{
              color: #8c8c93;
              cursor: default;
              pointer-events: none;
              .icon{
                filter: contrast(0);
              }
            }
          }
      }
      :deep().ql-size-small{
          font-size: 0.75em!important;
      }
      :deep().ql-size-large{
          font-size: 1.5em!important;
      }
      :deep().ql-size-huge{
          font-size: 2.5em!important;
      }
  </style>
